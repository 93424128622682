import UseAxios from '@/composables/types/useAxios'
import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'
import { OptionalFiltersParam } from '@/composables/types/useFilter'

import { BasicEntity, MetaProperties } from '@/api/types/misc'
import { Hateoas } from '@/api/types/hateoas'
import { BasicTaskOutput, TaskExtension } from '@/api/types/task'
import { BasicNoteOutput, NoteExtension } from '@/api/types/note'

interface InventoryItemOutput extends MetaProperties, InventoryItemAugmentations, Hateoas {
  name: string
  type: string
  serialNumber: string
  description: string
  inService: string // YYYY-MM-DD
  outOfService: string // YYYY-MM-DD
  purchasePrice: number
  organizationalUnit: BasicEntity
  notes: BasicNoteOutput[]
  tasks: BasicTaskOutput[]
}

enum InventoryItemStatus {
  IN_USE = 'IN_USE',
  AVAILABLE = 'AVAILABLE',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}

interface InventoryItemAugmentations extends NoteExtension, TaskExtension {
  status: InventoryItemStatus
}

type InventoryItemInput = Omit<
  InventoryItemOutput,
  keyof MetaProperties | keyof InventoryItemAugmentations | keyof Hateoas
>

type InventoryItemId = InventoryItemOutput['id']

type UseGetInventoryItems = UseAxiosPaginated<InventoryItemOutput>
type UseGetInventoryItemsBasic = UseAxios<BasicEntity[]>
type UseCreateInventoryItem = {
  createInventoryItem: (data: InventoryItemInput) => Promise<InventoryItemOutput>
} & UseAxios<InventoryItemOutput>
type UseUpdateInventoryItem = {
  updateInventoryItem: (id: InventoryItemId, data: InventoryItemOutput) => Promise<InventoryItemOutput>
} & UseAxios<InventoryItemOutput>
type UseDeleteInventoryItem = {
  deleteInventoryItem: (id: InventoryItemId) => Promise<void>
} & UseAxios<void>
type UseExportInventoryItems = {
  exportInventoryItems: (filters?: OptionalFiltersParam) => Promise<void>
} & UseAxios<void>

export {
  InventoryItemOutput,
  InventoryItemInput,
  InventoryItemId,
  UseGetInventoryItems,
  UseGetInventoryItemsBasic,
  UseCreateInventoryItem,
  UseUpdateInventoryItem,
  UseDeleteInventoryItem,
  InventoryItemStatus,
  UseExportInventoryItems,
}
