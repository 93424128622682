var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonTableView', {
    attrs: {
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.inventoryItems,
      "loading": _vm.state.isLoading,
      "with-info": false,
      "with-notes-and-tasks": "",
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.totalInventoryItems
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": _vm.listeners.onInventoryItemEdit,
      "click:tasks": function clickTasks($event) {
        return _vm.listeners.onOpenTasksDialog($event);
      },
      "click:notes": function clickNotes($event) {
        return _vm.listeners.onOpenNotesDialog($event);
      },
      "click:delete": function clickDelete($event) {
        return _vm.listeners.onInventoryItemDelete($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "table-header",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "pb-3 ma-0",
          attrs: {
            "align": "center"
          }
        }, [_c('v-btn', {
          staticClass: "mr-5 rounded-lg white",
          attrs: {
            "elevation": "0",
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.listeners.onToggleFilterDropdown
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1), _c('CommonExportMenu', {
          attrs: {
            "loading": _vm.state.isLoadingExport
          },
          on: {
            "export": function _export($event) {
              return _vm.listeners.onExportInventoryItems($event);
            }
          }
        }), _vm.functions.hasSufficientRights(_vm.constants.Rights.INVENTORY_ITEM_CREATE) ? _c('v-btn', {
          staticClass: "ml-auto rounded-lg",
          attrs: {
            "color": "primary",
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.state.isAddEditModalOpen = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('baseData.inventoryItems.actions.addInventoryItem')) + " ")], 1) : _vm._e()], 1), _c('v-expand-transition', {
          attrs: {
            "mode": "in-out"
          }
        }, [_vm.state.isFilterDropdownOpen ? _c('InventoryItemsFilterBar', {
          staticClass: "d-flex align-center",
          attrs: {
            "fields": _vm.constants.FILTER_FIELDS
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "item.actions.delete",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.INVENTORY_ITEM_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "type",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.type) + " ")];
      }
    }, {
      key: "inventory-item-status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c('span', {
          staticClass: "ml-2"
        }, [_vm._v(" " + _vm._s(_vm.functions.getStatus(item.status)) + " ")])])];
      }
    }], null, true)
  }, [_vm.state.isAddEditModalOpen ? _c('AddEditInventoryItemDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isEditMode,
      "inventory-item-to-edit": _vm.state.inventoryItemToEdit
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.functions.debouncedCb();
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditModalOpen", $$v);
      },
      expression: "state.isAddEditModalOpen"
    }
  }) : _vm._e(), _vm.state.isTasksDialogOpen ? _c('CommonTasksDialog', {
    attrs: {
      "tasks": _vm.state.inventoryItemTasks,
      "entity-name": "inventoryItem",
      "entity-id": _vm.state.tasksEntityId
    },
    on: {
      "reload-tasks": function reloadTasks($event) {
        return _vm.listeners.onReloadTasks();
      },
      "close": function close($event) {
        _vm.state.isTasksDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isTasksDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isTasksDialogOpen", $$v);
      },
      expression: "state.isTasksDialogOpen"
    }
  }) : _vm._e(), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.inventoryItemNotes,
      "entity-name": "inventoryItem",
      "entity-id": _vm.state.notesEntityId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": function close($event) {
        _vm.state.isNotesDialogOpen = false;
      }
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e(), _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteModalOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.functions.deleteInventoryItem();
      }
    },
    model: {
      value: _vm.state.isDeleteModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteModalOpen", $$v);
      },
      expression: "state.isDeleteModalOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.inventoryItems.deleteInventoryItem.text')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }