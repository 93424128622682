
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiFilter, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { cloneDeep, isEqual } from 'lodash-es'

import { useNotify } from '@/store'

import CommonTableView from '@/components/common/CommonTableView.vue'
import CommonExportMenu from '@/components/common/CommonExportMenu.vue'

import { useNoteDialog } from '@/composables/useNoteDialog'
import { useTaskDialog } from '@/composables/useTaskDialog'
import { useFilter } from '@/composables/useFilter'

import { dateDotNotationToDash } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { setSort } from '@/utils/manipulateTableSort'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import {
  useDeleteInventoryItem,
  useExportInventoryItems,
  useGetInventoryItems,
  useGetInventoryItemsBasic,
} from '@/api/inventoryItem'

import { InventoryItemOutput, InventoryItemStatus } from '@/api/types/inventoryItem'
import { FilterDataTypes, FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/baseData/views/inventoryItems/types'
import { Rights } from '@/api/types/right'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'

export default defineComponent({
  name: 'InventoryItems',
  components: {
    CommonTableView,
    CommonExportMenu,
    AddEditInventoryItemDialog: () =>
      import('@/views/baseData/views/inventoryItems/components/AddEditInventoryItemDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
    CommonTasksDialog: () => import('@/components/common/CommonTasksDialog.vue'),
    CommonNotesDialog: () => import('@/components/common/CommonNotesDialog.vue'),
    InventoryItemsFilterBar: () =>
      import('@/views/baseData/views/inventoryItems/components/InventoryItemsFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const TABLE_HEADERS = [
      {
        text: root.$t('baseData.inventoryItems.table.col.title.id'),
        value: 'id',
        sortable: true,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.name'),
        value: 'name',
        sortable: true,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.type'),
        value: 'type',
        sortable: true,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.serialNumber'),
        value: 'serialNumber',
        sortable: true,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.description'),
        value: 'description',
        sortable: true,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.status'),
        value: 'inventoryItemStatus',
        sortable: false,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.organizationalUnit'),
        value: 'organizationalUnit.name',
        sortable: false,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.tasksCount'),
        value: 'tasksCount',
        sortable: false,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.notesCount'),
        value: 'notesCount',
        sortable: false,
      },
      {
        text: root.$t('baseData.inventoryItems.table.col.title.actions'),
        value: 'actions',
        align: 'right',
        sortable: false,
      },
    ]

    const isEditMode = ref(false)

    const isAddEditModalOpen = ref(false)

    const inventoryItemToEdit = ref<null | InventoryItemOutput>(null)

    function onInventoryItemEdit(_, { item: inventoryItem }: { item: InventoryItemOutput }): void {
      if (hasSufficientRights(Rights.INVENTORY_ITEM_UPDATE)) {
        isEditMode.value = true

        isAddEditModalOpen.value = true

        inventoryItemToEdit.value = { ...inventoryItem }
      }
    }

    const isDeleteModalOpen = ref(false)
    const inventoryItemToDelete = ref<null | InventoryItemOutput>(null)

    const { deleteInventoryItem: deleteInventoryItemXhr } = useDeleteInventoryItem()

    async function deleteInventoryItem(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteInventoryItemXhr(inventoryItemToDelete.value!.id)

        addNotification({
          text: root.$t('baseData.inventoryItems.delete.message') as string,
          type: 'success',
        })

        isDeleteModalOpen.value = false

        debouncedCb.value()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onInventoryItemDelete(inventoryItem: InventoryItemOutput): void {
      inventoryItemToDelete.value = cloneDeep(inventoryItem)

      isDeleteModalOpen.value = true
    }

    function onCloseAddEditDialog(): void {
      isAddEditModalOpen.value = false

      inventoryItemToEdit.value = null

      isEditMode.value = false
    }

    const isFilterDropdownOpen = ref(false)

    const {
      exec: getInventoryItemsBasic,
      data: inventoryItemsBasic,
      isLoading: isLoadingInventoryItemsBasic,
    } = useGetInventoryItemsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnitsBasic } = useGetOrgUnitsBasic()

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!inventoryItemsBasic.value?.length && !orgUnits.value?.length) {
          getInventoryItemsBasic()
          getOrgUnitsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    function getStatus(status: InventoryItemStatus): string {
      switch (status) {
        case InventoryItemStatus.IN_USE:
          return root.$t('baseData.inventoryItems.filters.status.isInUse') as string
        case InventoryItemStatus.AVAILABLE:
          return root.$t('baseData.inventoryItems.filters.status.isAvailable') as string
        case InventoryItemStatus.OUT_OF_SERVICE:
          return root.$t('baseData.inventoryItems.filters.status.isOutOfService') as string
      }
    }

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.InventoryItemIds,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.inventoryItemIds'),
        items: computed(() => inventoryItemsBasic.value ?? []),
        isLoading: computed(() => isLoadingInventoryItemsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.Name,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.name'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Type,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.type'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.SerialNumbers,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.serialNumber'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.IsAvailable,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.isAvailable'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.IsOutOfService,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.isOutOfService'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.IsInUse,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.isInUse'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('baseData.inventoryItems.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnitsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
    ])

    const { exec: getInventoryItems, data: inventoryItems, isLoading, paginationResponse } = useGetInventoryItems()

    const totalInventoryItems = computed(() => paginationResponse.value.totalElements)

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      FILTER_FIELDS.value as FilterField[],
      init
    )
    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )
    setSort(vuetifyTableOptions, { by: 'name', desc: false })

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      await getInventoryItems({ params: { ...paginationParams.value, ...filterFieldsObject } })
    }

    const { exportInventoryItems, isLoading: isLoadingExport } = useExportInventoryItems()

    async function onExportInventoryItems(dateRange: { startMonth: string; endMonth: string }) {
      try {
        await exportInventoryItems({
          startMonth: dateDotNotationToDash(dateRange.startMonth),
          endMonth: dateDotNotationToDash(dateRange.endMonth),
        })

        addNotification({
          text: root.$t('misc.export.actions.success') as string,
          timeout: 5000,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const {
      isOpen: isNotesDialogOpen,
      notes: inventoryItemNotes,
      onClickOpenDialog: onOpenNotesDialog,
      onReload: onReloadNotes,
      entityId: notesEntityId,
    } = useNoteDialog<InventoryItemOutput>(inventoryItems, () => init(filterFieldsObject.value))

    const {
      isOpen: isTasksDialogOpen,
      tasks: inventoryItemTasks,
      onClickOpenDialog: onOpenTasksDialog,
      onReload: onReloadTasks,
      entityId: tasksEntityId,
    } = useTaskDialog<InventoryItemOutput>(inventoryItems, () => init(filterFieldsObject.value))

    return reactive({
      icons: {
        mdiPlus,
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        TABLE_HEADERS,

        FILTER_FIELDS,
      },
      state: {
        inventoryItems,
        isLoading,

        isDeleteModalOpen,

        isEditMode,
        isAddEditModalOpen,
        inventoryItemToEdit,

        isTasksDialogOpen,
        inventoryItemTasks,
        tasksEntityId,

        isNotesDialogOpen,
        inventoryItemNotes,
        notesEntityId,

        isFilterDropdownOpen,

        vuetifyTableOptions,
        totalInventoryItems,

        isLoadingExport,
      },
      functions: {
        deleteInventoryItem,

        getStatus,

        debouncedCb,

        hasSufficientRights,
      },
      listeners: {
        onCloseAddEditDialog,
        onInventoryItemEdit,
        onInventoryItemDelete,

        onExportInventoryItems,

        onOpenTasksDialog,
        onReloadTasks,

        onOpenNotesDialog,
        onReloadNotes,

        onToggleFilterDropdown,
      },
    })
  },
})
