enum FILTER_FIELD_KEY {
  InventoryItemIds = 'ids',
  Name = 'name',
  Type = 'type',
  SerialNumbers = 'serialNumbers',
  IsAvailable = 'available',
  IsOutOfService = 'isOutOfService',
  IsInUse = 'inUse',
  OrganizationalUnitIds = 'organizationalUnitIds',
}

export { FILTER_FIELD_KEY }
